import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Box from 'common/src/components/Box';
import Text from 'common/src/components/Text';
import Heading from 'common/src/components/Heading';
import Container from 'common/src/components/UI/Container';
import moment from 'moment'
import { OutboundLink } from "gatsby-plugin-google-analytics"

import {
  SkillItem,
  SkillDetails,
  SkillProgress,
  SuccessRate,
  ProgressBar,
  SkillIcon,
  SkillAbout,
} from './index.style';
import { ic_thumb_up } from 'react-icons-kit/md/ic_thumb_up';

const QittaSection = ({
  sectionWrapper,
  secTitleWrapper,
  secTitle,
  secDescription,
  row,
  col,
  qittaTitle,
  qittaDescription,
  qittaSuccessRate,
  successRateText,
}) => {
  const Data = useStaticQuery(graphql`
    query {
        allQiitaYaml {
            edges {
                node {
                    id
                    title
                    url
                    tags
                    likesCount
                    createdAt
                }
            }
        }       
    }
  `);

  const formatter = new Intl.NumberFormat('ja-JP')
  const totalLikesCount = Data.allQiitaYaml.edges.map(({ node }) => node.likesCount).reduce((p, x) => p + x, 0)
  const totalPageCount = Data.allQiitaYaml.edges.length

  return (
    <Box {...sectionWrapper} as="section" id="qiita_section">
      <Container noGutter mobileGutter width="1200px">
        <Box {...secTitleWrapper}>
          <Heading {...secTitle} content="Qiita" />
          <Text
            {...secDescription}
            content={`${formatter.format(totalPageCount)} pages : Total ${formatter.format(totalLikesCount)} LGTM.`}
          />
        </Box>

        <Box {...row}>
          {Data.allQiitaYaml.edges.map(({ node }, index) => (
              <Box {...col} key={`qitta-item-${index}`}>
                <OutboundLink href={node.url} target="_blank" rel="noopener noreferrer">
                  <Heading content={node.title} {...qittaTitle} />
                  <Text content={`${formatter.format(node.likesCount)} LGTM / ${node.tags.join(', ')} / ${moment(node.createdAt).format('MMM D, YYYY')}`} {...qittaDescription} />
                </OutboundLink>
              </Box>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

QittaSection.propTypes = {
  sectionWrapper: PropTypes.object,
  secTitleWrapper: PropTypes.object,
  secTitle: PropTypes.object,
  secDescription: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  qittaTitle: PropTypes.object,
  qittaDescription: PropTypes.object,
  qittaSuccessRate: PropTypes.object,
  successRateText: PropTypes.object,
};

QittaSection.defaultProps = {
  sectionWrapper: {
    pt: '40px',
    pb: '40px',
  },
  secTitleWrapper: {
    mb: '30px',
  },
  secTitle: {
    fontSize: ['22px', '26px', '26px', '30px', '30px'],
    fontWeight: '700',
    color: '#302b4e',
    lineHeight: '1.34',
    mb: ['15px', '18px', '18px', '20px', '20px'],
    textAlign: 'left',
  },
  secDescription: {
    fontSize: ['15px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
    textAlign: 'left',
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: ['-15px', '-15px', '-15px', '-25px', '-25px'],
    mr: ['-15px', '-15px', '-15px', '-25px', '-25px'],
  },
  col: {
    width: [1, 1, 1],
    pl: ['15px', '15px', '15px', '25px', '25px'],
    pr: ['15px', '15px', '15px', '25px', '25px'],
    mb: '30px',
  },
  qittaTitle: {
    fontSize: ['16px', '18px', '18px', '20px', '20px'],
    fontWeight: '600',
    color: '#302b4e',
    mb: '4px',
  },
  qittaDescription: {
    fontSize: ['15px', '15px', '15px', '16px', '16px'],
    fontWeight: '400',
    color: '#43414e',
    lineHeight: '1.5',
    mb: '0',
  },
  qittaSuccessRate: {
    fontSize: ['15px', '15px', '14px', '15px', '16px'],
    fontWeight: '400',
    color: '#302b4e',
    lineHeight: '1.5',
    mb: '0',
  },
  successRateText: {
    ml: '.3em',
    display: ['none', 'none', 'none', 'none', 'inline-block'],
  },
};

export default QittaSection;
